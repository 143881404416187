import { Center, Spinner, SpinnerProps } from '@chakra-ui/react';

type Props = {} & SpinnerProps;

export function LoadingIndicator({ ...rest }: Props) {
  return (
    <Center h={'full'} minH={'full'}>
      <Spinner speed="1s" size="xl" thickness="2px" {...rest} />
    </Center>
  );
}
