import { getConfigGroup } from 'api';
import React, { createContext, useContext, useEffect, useState } from 'react';

interface SiteConfig {
  isLoading: boolean;
  useSubcategory: boolean;
  homeContents: string;
}

const siteContext = createContext({
  useSubcategory: false,
  homeContents: '',
} as SiteConfig);

export const SiteConfigProvider: React.FC = ({ children }) => {
  const config = useProvideConfig();
  return <siteContext.Provider value={config}>{children}</siteContext.Provider>;
};

export const useSiteConfig = () => {
  return useContext(siteContext);
};

const useProvideConfig = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [homeContents, setHomeContents] = useState('');

  useEffect(() => {
    console.debug('useProvideConfig');
    async function loadConfig() {
      try {
        console.debug('loading config...');
        const configGroup = await getConfigGroup('app');
        console.debug('app config', configGroup);
        const homeContents = configGroup.find((x) => x.key === 'home_contents');
        if (homeContents) {
          setHomeContents(homeContents.value);
        }
      } catch {
        setHomeContents('');
      } finally {
        setIsLoading(false);
      }
    }
    loadConfig();
  }, []);

  return {
    isLoading,
    homeContents,
    useSubcategory: false,
  };
};
