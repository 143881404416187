import { API, GraphQLResult } from '@aws-amplify/api';
import {
  Config,
  Issue,
  ListIssuesByCategoryConditionInput,
  ListOptionsConditionInput,
  ListOwnIssuesConditionInput,
  Mutation,
  MutationCloseIssueArgs,
  MutationOpenTalkArgs,
  MutationPostTalkMessageArgs,
  MutationRegisterClientArgs,
  MutationUpdateOwnProfileArgs,
  Option,
  Query,
  QueryGetConfigGroupArgs,
  QueryGetIssueDetailArgs,
  QueryGetTalkMessagesArgs,
  QueryGetUserArgs,
  QueryListIssuesByCategoryArgs,
  QueryListOptionsArgs,
  QueryListOwnIssuesArgs,
  Subscription,
  SubscriptionOnPostTalkMessageArgs,
  Talk,
  TalkMessage,
  UpdateOwnProfileInput,
  User,
} from 'API';

export * from 'API';

export async function me(): Promise<User | undefined> {
  const me = /* GraphQL */ `
    query Me {
      me {
        userId
        name
        category
        avatarImageUrl
        organizationId
        welcomed
      }
    }
  `;
  const result = (await API.graphql({
    query: me,
  })) as GraphQLResult<Query>;
  return result.data?.me ?? undefined;
}

export async function getUser(userId: string): Promise<User> {
  const query = /* GraphQL */ `
    query GetUser($userId: ID!) {
      getUser(userId: $userId) {
        userId
        name
        category
        avatarImageUrl
        organizationId
        welcomed
      }
    }
  `;
  const { data, errors } = (await API.graphql({
    query: query,
    variables: {
      userId,
    } as QueryGetUserArgs,
  })) as GraphQLResult<Query>;
  if (errors?.length) {
    throw errors;
  }
  if (!data?.getUser) {
    throw Error('user not respond');
  }
  return data.getUser;
}

export async function registerClient(user: {
  name: string;
  lineAccountId?: string;
  picture?: string;
}): Promise<User> {
  const registerClient = /* GraphQL */ `
    mutation RegisterClient($input: RegisterClientInput!) {
      registerClient(input: $input) {
        userId
        name
        category
        avatarImageUrl
        organizationId
        welcomed
      }
    }
  `;
  const result = (await API.graphql({
    query: registerClient,
    variables: {
      input: {
        organizationId: '-',
        name: user.name || '',
        lineAccountId: user.lineAccountId || undefined,
        avatarImageUrl: user.picture || undefined,
      },
    } as MutationRegisterClientArgs,
  })) as GraphQLResult<Mutation>;
  if (!result.data?.registerClient) {
    throw Error('ユーザー登録に失敗しました');
  }
  return result.data?.registerClient;
}

export async function listOwnIssues(
  condition?: ListOwnIssuesConditionInput,
  limit = 5,
  nextToken?: string
): Promise<{ items: Issue[]; nextToken?: string }> {
  type ListOwnIssuesQuery = {
    listOwnIssues?: {
      items: Issue[];
      nextToken?: string | null;
    } | null;
  };
  const query = /* GraphQL */ `
    query ListOwnIssues(
      $condition: ListOwnIssuesConditionInput
      $limit: Int
      $nextToken: String
    ) {
      listOwnIssues(
        condition: $condition
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          issueId
          clientId
          category
          subcategory
          createdAt
          updatedAt
          deleted
          content
          status
          result
          resultDescription
          requestCount
          talkCount
          talks {
            talkId
            issueId
            counselorId
            createdAt
            updatedAt
            deleted
            status
            messages(limit: 1) {
              items {
                messageId
                userId
                createdAt
                content
              }
            }
            counselor {
              userId
              name
              title
              avatarImageUrl
            }
          }
          solutionTalk {
            talkId
            counselorId
            counselor {
              userId
              name
            }
          }
        }
        nextToken
      }
    }
  `;
  const { data, errors } = (await API.graphql({
    query,
    variables: {
      condition,
      limit,
      nextToken,
    } as QueryListOwnIssuesArgs,
  })) as GraphQLResult<ListOwnIssuesQuery>;
  if (errors?.length) {
    throw errors;
  }
  if (!data?.listOwnIssues) {
    throw Error('messages not respond');
  }
  return {
    items: data.listOwnIssues.items,
    nextToken: data.listOwnIssues.nextToken || undefined,
  };
}

export async function listIssuesByCategory(
  condition?: ListIssuesByCategoryConditionInput,
  limit = 5,
  nextToken?: string
): Promise<{ items: Issue[]; nextToken?: string }> {
  type listIssuesByCategoryQuery = {
    listIssuesByCategory?: {
      items: Issue[];
      nextToken?: string | null;
    } | null;
  };
  const query = /* GraphQL */ `
    query ListIssuesByCategory(
      $condition: ListIssuesByCategoryConditionInput
      $limit: Int
      $nextToken: String
    ) {
      listIssuesByCategory(
        condition: $condition
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          issueId
          clientId
          category
          subcategory
          createdAt
          updatedAt
          deleted
          content
          status
          result
          resultDescription
          requestCount
          talkCount
          talks {
            talkId
            issueId
            counselorId
            createdAt
            updatedAt
            deleted
            status
            messages(limit: 1) {
              items {
                messageId
                userId
                createdAt
                content
              }
            }
          }
          client {
            userId
            name
            avatarImageUrl
          }
          solutionTalk {
            talkId
            counselorId
            counselor {
              userId
              name
            }
          }
        }
        nextToken
      }
    }
  `;
  const { data, errors } = (await API.graphql({
    query,
    variables: {
      condition,
      limit,
      nextToken,
    } as QueryListIssuesByCategoryArgs,
  })) as GraphQLResult<listIssuesByCategoryQuery>;
  if (errors?.length) {
    throw errors;
  }
  if (!data?.listIssuesByCategory) {
    throw Error('no issue respond');
  }
  return {
    items: data.listIssuesByCategory.items,
    nextToken: data.listIssuesByCategory.nextToken || undefined,
  };
}

export async function getIssueDetail(issueId: string): Promise<Issue> {
  const query = /* GraphQL */ `
    query GetIssueDetail($issueId: ID!) {
      getIssueDetail(issueId: $issueId) {
        issueId
        clientId
        category
        subcategory
        createdAt
        updatedAt
        deleted
        content
        status
        result
        resultDescription
        requestCount
        suggestionCount
        talkCount
        talks {
          talkId
          issueId
          counselorId
          createdAt
          updatedAt
          deleted
          status
        }
        client {
          userId
          category
          name
          title
          organizationId
          affiliation
          profile
          introduction
          createdAt
          updatedAt
          deleted
          lineAccountId
          avatarImageUrl
          welcomed
        }
        solutionTalk {
          talkId
          counselorId
          counselor {
            userId
            name
          }
        }
      }
    }
  `;
  const { data, errors } = (await API.graphql({
    query: query,
    variables: {
      issueId,
    } as QueryGetIssueDetailArgs,
  })) as GraphQLResult<Query>;
  if (errors?.length) {
    throw errors;
  }
  if (!data?.getIssueDetail) {
    throw Error('issue detail not respond');
  }
  return data.getIssueDetail;
}

export async function getTalkMessages(
  talkId: string,
  nextToken?: string
): Promise<{ items: TalkMessage[]; nextToken?: string }> {
  const query = /* GraphQL */ `
    query GetTalkMessages(
      $condition: GetTalkMessageConditionInput
      $limit: Int
      $nextToken: String
    ) {
      getTalkMessages(
        condition: $condition
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          talkId
          messageId
          userId
          createdAt
          updatedAt
          content
          user {
            name
            avatarImageUrl
          }
        }
        nextToken
      }
    }
  `;
  type GetTalkMessagesQuery = {
    getTalkMessages?: {
      __typename: 'TalkMessageConnection';
      items: TalkMessage[];
      nextToken?: string | null;
    } | null;
  };
  const { data, errors } = (await API.graphql({
    query,
    variables: {
      condition: {
        talkId,
      },
      limit: 25,
      nextToken,
    } as QueryGetTalkMessagesArgs,
  })) as GraphQLResult<GetTalkMessagesQuery>;
  if (errors?.length) {
    throw errors;
  }
  if (!data?.getTalkMessages) {
    throw Error('messages not respond');
  }
  return {
    items: data.getTalkMessages.items,
    nextToken: data.getTalkMessages.nextToken || undefined,
  };
}

export async function openTalk(
  issueId: string,
  firstMessage: string
): Promise<Talk> {
  const query = /* GraphQL */ `
    mutation OpenTalk($input: OpenTalkInput) {
      openTalk(input: $input) {
        talkId
        issueId
        counselorId
        createdAt
        updatedAt
        status
        messages {
          nextToken
        }
      }
    }
  `;
  const { data, errors } = (await API.graphql({
    query: query,
    variables: {
      input: {
        issueId,
        firstMessage,
      },
    } as MutationOpenTalkArgs,
  })) as GraphQLResult<Mutation>;
  if (errors?.length) {
    throw errors;
  }
  if (!data?.openTalk) {
    throw Error('talk not respond');
  }
  return data.openTalk;
}

export async function postTalkMessage(
  talkId: string,
  content: string
): Promise<TalkMessage> {
  const query = /* GraphQL */ `
    mutation PostTalkMessage($input: PostTalkMessageInput!) {
      postTalkMessage(input: $input) {
        talkId
        messageId
        userId
        createdAt
        updatedAt
        content
        user {
          name
          avatarImageUrl
        }
      }
    }
  `;
  const { data, errors } = (await API.graphql({
    query: query,
    variables: {
      input: {
        talkId,
        content,
      },
    } as MutationPostTalkMessageArgs,
  })) as GraphQLResult<Mutation>;
  if (errors?.length) {
    throw errors;
  }
  if (!data?.postTalkMessage) {
    throw Error('message not respond');
  }
  return data.postTalkMessage;
}

export function subscribePostTalkMessage(
  talkId: string,
  onGotMessage: (message: TalkMessage) => any,
  onError: (error: any) => any
) {
  // ※ onPostTalkMessage の結果は PostTalkMessage と合わせておく必要あり
  const query = /* GraphQL */ `
    subscription OnPostTalkMessage($talkId: ID!) {
      onPostTalkMessage(talkId: $talkId) {
        talkId
        messageId
        userId
        createdAt
        updatedAt
        content
        user {
          name
          avatarImageUrl
        }
      }
    }
  `;

  type OnPostTalkMessageSubscriptionEvent = { value: { data: Subscription } };

  const client = API.graphql({
    query,
    variables: {
      talkId,
    } as SubscriptionOnPostTalkMessageArgs,
  });

  if ('subscribe' in client) {
    return client.subscribe({
      next: (event: OnPostTalkMessageSubscriptionEvent) => {
        console.debug(event);
        const {
          value: { data },
        } = event;
        if (data.onPostTalkMessage) {
          const message: TalkMessage = data.onPostTalkMessage;
          onGotMessage(message);
        }
      },
      error: (error) => onError(error),
    });
  }
  throw new Error('failed to subscribe');
}

export async function listOptions(
  condition: ListOptionsConditionInput
): Promise<Option[]> {
  // type ListOptionsQuery = {
  //   listOptions?: {
  //     items: Option[],
  //     nextToken?: string | null,
  //   } | null,
  // };
  const query = /* GraphQL */ `
    query ListOwnIssues(
      $condition: ListOptionsConditionInput
      $limit: Int
      $nextToken: String
    ) {
      listOptions(condition: $condition, limit: $limit, nextToken: $nextToken) {
        items {
          sortOrder
          label
          key
          group
          disabled
        }
        nextToken
      }
    }
  `;
  const { data, errors } = (await API.graphql({
    query,
    variables: {
      condition,
      limit: null,
      nextToken: null,
    } as QueryListOptionsArgs,
  })) as GraphQLResult<Query>;
  if (errors?.length) {
    throw errors;
  }
  if (!data?.listOptions) {
    throw Error('options not respond');
  }
  return data.listOptions.items;
}

export async function getConfigGroup(group: string): Promise<Config[]> {
  const query = /* GraphQL */ `
    query GetConfigGroup($group: String!) {
      getConfigGroup(group: $group) {
        items {
          group
          key
          value
          disabled
        }
      }
    }
  `;
  const { data, errors } = (await API.graphql({
    query,
    variables: {
      group,
    } as QueryGetConfigGroupArgs,
  })) as GraphQLResult<Query>;
  if (errors?.length) {
    throw errors;
  }
  if (!data?.getConfigGroup) {
    throw Error('config not respond');
  }
  return data.getConfigGroup.items;
}

export async function updateOwnProfile(
  input: UpdateOwnProfileInput
): Promise<User> {
  const query = /* GraphQL */ `
    mutation UpdateOwnProfile($input: UpdateOwnProfileInput!) {
      updateOwnProfile(input: $input) {
        userId
        category
        name
        title
        organizationId
        affiliation
        profile
        introduction
        createdAt
        updatedAt
        deleted
        lineAccountId
        avatarImageUrl
        welcomed
      }
    }
  `;
  const result = (await API.graphql({
    query,
    variables: {
      input,
    } as MutationUpdateOwnProfileArgs,
  })) as GraphQLResult<Mutation>;
  if (!result.data?.updateOwnProfile) {
    throw Error('ユーザー情報の更新に失敗しました');
  }
  return result.data?.updateOwnProfile;
}

export type UserMinimum = Pick<User, 'userId' | 'name' | 'avatarImageUrl'>;

export type IssueCompact = Pick<
  Issue,
  | 'issueId'
  | 'clientId'
  | 'category'
  | 'subcategory'
  | 'deleted'
  | 'content'
  | 'status'
  | 'createdAt'
  | 'updatedAt'
> & {
  client: UserMinimum;
};

/**
 * トーク開始画面で使用する Issue と Talk の概要を取得します。
 * ※この関数は Counselor 専用です。
 * @param issueId
 * @param talkId
 * @returns
 */
export async function getIssueToOpenTalk(
  issueId: string
): Promise<IssueCompact> {
  const query = /* GraphQL */ `
    query ($issueId: ID!) {
      issue: getIssueDetail(issueId: $issueId) {
        issueId
        clientId
        category
        subcategory
        deleted
        content
        status
        createdAt
        updatedAt
        client {
          userId
          name
          avatarImageUrl
        }
        solutionTalk {
          talkId
          counselorId
          counselor {
            userId
            name
          }
        }
      }
    }
  `;
  const { data, errors } = (await API.graphql({
    query: query,
    variables: {
      issueId,
    },
  })) as GraphQLResult<{ issue: IssueCompact }>;
  if (errors?.length) {
    throw errors;
  }
  if (!data) {
    throw Error('issue not respond');
  }
  return data.issue;
}

export type IssueTalk = {
  issue: Issue;
  talk: Talk;
};

/**
 * トーク画面で使用する Issue と Talk の概要を取得します
 * @param issueId
 * @param talkId
 * @returns
 */
export async function getIssueTalk(
  issueId: string,
  talkId: string
): Promise<IssueTalk> {
  const query = /* GraphQL */ `
    query ($issueId: ID!, $talkId: ID!) {
      issue: getIssueDetail(issueId: $issueId) {
        issueId
        clientId
        category
        subcategory
        deleted
        content
        status
        createdAt
        updatedAt
        client {
          userId
          name
          avatarImageUrl
        }
        solutionTalk {
          talkId
          counselorId
          counselor {
            userId
            name
          }
        }
      }
      talk: getTalkDetail(issueId: $issueId, talkId: $talkId) {
        talkId
        issueId
        counselorId
        createdAt
        updatedAt
        status
        messages(limit: 25) {
          items {
            talkId
            messageId
            userId
            createdAt
            updatedAt
            content
            user {
              name
              avatarImageUrl
            }
          }
          nextToken
        }
        counselor {
          userId
          name
          avatarImageUrl
        }
      }
    }
  `;
  const { data, errors } = (await API.graphql({
    query: query,
    variables: {
      issueId,
      talkId,
    },
  })) as GraphQLResult<IssueTalk>;
  if (errors?.length) {
    throw errors;
  }
  if (!data) {
    throw Error('issue/talk not respond');
  }
  return data;
}

export async function closeIssue(
  issueId: string,
  solutionTalkId?: string
): Promise<Issue> {
  const closeIssue = /* GraphQL */ `
    mutation CloseIssue($issueId: ID!, $solutionTalkId: ID) {
      closeIssue(issueId: $issueId, solutionTalkId: $solutionTalkId) {
        issueId
        clientId
        category
        subcategory
        deleted
        content
        status
        createdAt
        updatedAt
        client {
          userId
          name
          avatarImageUrl
        }
        solutionTalk {
          talkId
          counselorId
          counselor {
            userId
            name
          }
        }
      }
    }
  `;
  const result = (await API.graphql({
    query: closeIssue,
    variables: {
      issueId,
      solutionTalkId,
    } as MutationCloseIssueArgs,
  })) as GraphQLResult<Mutation>;
  if (!result.data?.closeIssue) {
    throw Error('クローズに失敗しました');
  }
  return result.data?.closeIssue;
}
