import {
  IconButton,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';

type Props = {
  onCloseIssue?: () => void;
} & MenuButtonProps;

const IssueSummaryMenu = ({ onCloseIssue, ...rest }: Props) => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<BsThreeDotsVertical />}
        variant="ghost"
        colorScheme="blackAlpha"
        borderRadius="full"
        {...rest}
      />
      <MenuList boxShadow="xl">
        <MenuItem
          icon={<MdClose />}
          isDisabled={!onCloseIssue}
          onClick={onCloseIssue}
        >
          相談を終了する
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default IssueSummaryMenu;
