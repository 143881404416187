import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { closeIssue } from 'api';
import { Issue, Talk } from 'API';
import { NoItemIndicator } from 'components/common/NoItemIndicator';
import StatusBadge from 'components/common/StatusBadge';
import { UserAvatar } from 'components/common/UserAvatar';
import { useAuth } from 'hooks/use-auth';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { MdInfo } from 'react-icons/md';
import { useNavigate } from 'react-router';
import IssueSummaryMenu from '../IssueSummaryMenu';
import { TalkSummary } from '../TalkSummary';

type Props = {
  issue: Issue;
  onIssueChanged?: (issue: Issue) => void;
};

export function IssueSummary({ issue, onIssueChanged }: Props) {
  const navigate = useNavigate();
  const { user, isMemberOf } = useAuth();

  const updatedAt = () => {
    if (!issue.updatedAt && !issue.createdAt) return '';
    const date = DateTime.fromISO(issue.updatedAt ?? issue.createdAt).toLocal();
    return date.toFormat('MM/dd HH:mm');
  };

  const isClosed = issue.status === 'CLOSED';
  const isClient = user?.userId === issue.clientId;

  const createTalkSummary = (item: Talk) => {
    return (
      <TalkSummary
        key={`talk-${item.talkId}`}
        issueId={issue.issueId}
        talkId={item.talkId}
        user={item.counselor || null}
        lastMessage={item.messages?.items[0]}
        status={item.status ?? 'OPEN'}
        isSolution={issue.solutionTalk?.talkId === item.talkId}
      />
    );
  };

  const contentAbbr = () => {
    if (!issue.content)
      return (
        <Text as="span" color={'gray.400'}>
          {'(内容がありません)'}
        </Text>
      );
    return issue.content;
  };

  const ClientAvatar = () => {
    if (isClient) {
      return null;
    }
    return (
      <UserAvatar
        imageUrl={issue.client?.avatarImageUrl || undefined}
        name={issue.client?.name}
      />
    );
  };

  const ClientName = () => {
    const name = issue.client?.name ?? user?.name ?? '';
    return (
      <Heading as={'span'} fontSize={'sm'}>
        {name}
      </Heading>
    );
  };

  const ReplyButton = () => {
    return (
      <Flex justifyContent={'flex-end'}>
        <Button onClick={() => navigate(`/issues/${issue.issueId}/talks/new`)}>
          返信する
        </Button>
      </Flex>
    );
  };

  const NoTalkElement = () => {
    return (
      <HStack
        p={2}
        bg={'glass.500'}
        alignItems={'flex-start'}
        borderRadius="md"
      >
        <Icon as={MdInfo} color={'blue.500'} />
        <NoItemIndicator
          message={'担当する専門家が確認中です\n返信をお待ちください'}
        />
      </HStack>
    );
  };

  const TalksBox = () => {
    if (isMemberOf('counselor')) {
      // Counselor モード
      const mine = issue.talks?.find((x) => x.counselorId === user?.userId);
      const others = issue.talks?.filter((x) => x.counselorId !== user?.userId);
      return (
        <Box w={'full'}>
          {mine ? createTalkSummary(mine) : !isClosed && <ReplyButton />}
          {others?.length ? (
            <Alert
              mt={2}
              p={1}
              status={'info'}
              bg={'glass.500'}
              borderRadius="md"
            >
              <AlertIcon boxSize={'1rem'} />
              <Text
                fontSize={'xs'}
              >{`他に ${others.length} 名から回答があります`}</Text>
            </Alert>
          ) : null}
        </Box>
      );
    } else {
      // Client モード
      const talks = issue.talks;
      if (talks?.length) {
        talks.sort(
          (a, b) =>
            (issue.solutionTalk?.talkId === a.talkId ? 0 : 1) -
            (issue.solutionTalk?.talkId === b.talkId ? 0 : 1)
        );
      }
      return (
        <Box w={'full'}>
          {talks?.length ? talks?.map(createTalkSummary) : <NoTalkElement />}
        </Box>
      );
    }
  };

  const onCloseIssueHandler = useMemo(
    () =>
      isClosed
        ? undefined
        : async () => {
            if (!onIssueChanged) {
              return;
            }
            const message = 'この相談を未解決で終了しますか？';
            if (!window.confirm(message)) {
              return;
            }
            try {
              const result = await closeIssue(issue.issueId);
              onIssueChanged(result);
            } catch (error) {
              console.error('closeIssue', error);
              alert('相談の終了に失敗しました');
            }
          },
    [onIssueChanged, isClosed, issue.issueId]
  );

  return (
    <VStack as="article" alignItems={'stretch'} mb={4}>
      <Stack
        bg={useColorModeValue('glass.400', 'glass.400')}
        boxShadow={'lg'}
        p={4}
        pt={4}
        align={'flex-start'}
        pos={'relative'}
        rounded={'md'}
      >
        <HStack spacing={4} w={'full'} alignItems={'flex-start'}>
          <ClientAvatar />
          <VStack spacing={1} w={'full'} alignItems={'flex-start'}>
            <Flex alignItems={'center'} w={'full'} gap={1}>
              <HStack flex={1} spacing={1}>
                <ClientName />
                <StatusBadge status={issue.status} />
              </HStack>
              <Text fontSize={'xs'} color={'gray.500'} ml={'auto'}>
                {updatedAt()}
              </Text>
              {isClient && (
                <IssueSummaryMenu
                  onCloseIssue={onCloseIssueHandler}
                  m={-2}
                  ml={2}
                />
              )}
            </Flex>
            <Text
              w={'full'}
              whiteSpace={'pre-line'}
              noOfLines={4}
              fontSize={'sm'}
            >
              {contentAbbr()}
            </Text>
          </VStack>
        </HStack>
        <Box pl={4} pt={2} w={'full'}>
          <TalksBox />
        </Box>
      </Stack>
    </VStack>
  );
}
