import { Box, Button, Icon, Input, Text, VStack } from '@chakra-ui/react';
import { Card } from 'components/common/Card';
import { useState } from 'react';
import { BiMessageRoundedDots } from 'react-icons/bi';
import { BsFillPersonCheckFill } from 'react-icons/bs';
import { MdOutlineLock } from 'react-icons/md';
import { Carousel } from 'react-responsive-carousel';

type Props = {
  defaultUsername?: string;
  onStart?: (username: string) => any;
  loading: boolean;
};

/**
 * 環境変数に含まれる改行コード (\n) を改行に変換する
 * @param str
 * @returns
 */
function replaceLf(str: string) {
  return str.replace(/\\n/g, '\n');
}

export function WelcomeCarousel(props: Props) {
  const defaultUsername = props.defaultUsername || '';
  const siteTitle = process.env.REACT_APP_SITE_TITLE || 'お悩み相談室';
  const welcomeMessage1 = replaceLf(
    process.env.REACT_APP_WELCOME_MESSAGE1 ||
      `ここは ${siteTitle} の相談サイトです。\\n相談内容に応じて、各分野の専門家がご回答・ご提案をいたします。`
  );
  const welcomeMessage2 = replaceLf(
    process.env.REACT_APP_WELCOME_MESSAGE2 ||
      `あなたの個人情報や相談内容は保護されています。`
  );
  const welcomeMessage3 = replaceLf(
    process.env.REACT_APP_WELCOME_MESSAGE3 ||
      `さぁ、あなたのニックネームを設定して、まずは一つ相談を書いてみましょう！`
  );

  const [currentSlide, setCurrentSlide] = useState(0);

  const goNext = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const updateCurrentSlide = (index: number) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const [username, setUsername] = useState(defaultUsername);

  const onUsernameInput = (input: HTMLInputElement) => {
    setUsername(input.value);
  };

  const slides = [
    {
      icon: (
        <Icon as={BiMessageRoundedDots} boxSize={128} color={'brand.300'} />
      ),
      content: (
        <Text align={'left'} whiteSpace="pre-line">
          {welcomeMessage1}
        </Text>
      ),
      button: <Button onClick={goNext}>次へ</Button>,
    },
    {
      icon: (
        <Icon
          as={MdOutlineLock}
          boxSize={128}
          color={'brand.300'}
          transform={'rotate(-15deg)'}
        />
      ),
      content: (
        <Text align={'left'} whiteSpace="pre-line">
          {welcomeMessage2}
        </Text>
      ),
      button: <Button onClick={goNext}>次へ</Button>,
    },
    {
      icon: (
        <Icon as={BsFillPersonCheckFill} boxSize={128} color={'brand.300'} />
      ),
      content: (
        <>
          <Text align={'left'} whiteSpace="pre-line">
            {welcomeMessage3}
          </Text>
          <Input
            mt={4}
            maxLength={50}
            placeholder="あなたのニックネーム"
            defaultValue={username}
            onInput={(e) => onUsernameInput(e.target as HTMLInputElement)}
          />
        </>
      ),
      button: (
        <Button
          isLoading={props.loading}
          disabled={!username || props.loading}
          onClick={() => props.onStart?.call(null, username)}
        >
          利用を開始する
        </Button>
      ),
    },
  ];

  const createCarouselItem = (item: any) => {
    return (
      <Card key={item} p={8}>
        <VStack>
          <Box>{item.icon}</Box>
          <Box>{item.content}</Box>
          <Box pt={8}>{item.button}</Box>
        </VStack>
      </Card>
    );
  };

  return (
    <Box p={8}>
      <Carousel
        autoPlay={false}
        swipeable={true}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        selectedItem={currentSlide}
        onChange={updateCurrentSlide}
      >
        {slides.map(createCarouselItem)}
      </Carousel>
    </Box>
  );
}
