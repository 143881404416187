import { Box, Icon, VStack } from '@chakra-ui/react';
import { MdStars } from 'react-icons/md';

export default function ResolveBadge() {
  return (
    <VStack
      borderRadius="md"
      bgColor="yellow.400"
      color="yellow.700"
      p={1}
      spacing={0}
    >
      <Icon as={MdStars} boxSize="24px" title="解決" m={0} />
      <Box fontSize={8} whiteSpace="pre" mt={0}>
        解決
      </Box>
    </VStack>
  );
}
