import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { BsPatchCheck, BsThreeDotsVertical } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';

type Props = {
  onCloseIssue: (resolved: boolean) => void;
};

const TalkScreenMenu = ({ onCloseIssue }: Props) => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<BsThreeDotsVertical />}
        variant="ghost"
        colorScheme="blackAlpha"
      />
      <MenuList boxShadow="xl">
        <MenuItem icon={<BsPatchCheck />} onClick={() => onCloseIssue(true)}>
          解決済みにする
        </MenuItem>
        <MenuItem icon={<MdClose />} onClick={() => onCloseIssue(false)}>
          相談を終了する
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default TalkScreenMenu;
