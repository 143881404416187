import { Grid, GridItem } from '@chakra-ui/react';
import { getIssueToOpenTalk, IssueCompact, openTalk, UserMinimum } from 'api';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { MessageBalloon } from 'components/pages/talks/MessageBalloon';
import { MessageInput } from 'components/pages/talks/MessageInput';
import MainLayout from 'layouts/MainLayout';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

type Params = {
  issueId: string;
};

export default function NewTalkPage() {
  const navigate = useNavigate();
  const { issueId } = useParams<Params>() as Params;

  const [loading, setLoading] = useState<boolean>(true);
  const [talkName, setTalkName] = useState<string>('');
  const [issue, setIssue] = useState<IssueCompact>();
  const [client, setClient] = useState<UserMinimum>();

  // ユーザー情報取得
  useEffect(() => {
    let unmounted = false;
    (async () => {
      try {
        const issue = await getIssueToOpenTalk(issueId);
        console.debug('getIssueToOpenTalk', { issue });
        if (unmounted) {
          return;
        }
        setIssue(issue);
        setClient(issue.client);
        setTalkName(issue.client.name);
        setLoading(false);
      } catch (error) {
        console.error('getIssueToOpenTalk', error);
      }
    })();
    return () => {
      console.debug('unmounting...');
      unmounted = true;
      console.debug('unmounted');
    };
  }, [issueId]);

  // 送信処理 (トーク開始処理)
  const onSend = async (content: string) => {
    try {
      const talk = await openTalk(issueId, content);
      console.debug('openTalk', talk);
      // メッセージ画面にジャンプ
      navigate(`/issues/${issueId}/talks/${talk.talkId}`);
    } catch (error) {
      console.error('openTalk', error);
    }
  };

  // 戻るボタンを押したときの操作
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <MainLayout title={talkName} handleBack={handleBack} hideFooter>
      <Grid templateRows={'auto 1fr auto'} height={'full'}>
        <GridItem
          bg={'glass.300'}
          boxShadow={'md'}
          overflowY={'auto'}
          maxHeight={'40vh'}
        >
          {loading ? null : (
            <MessageBalloon
              messageId={''}
              message={issue?.content ?? ''}
              mine={false}
              userName={client?.name ?? ''}
              avatarUrl={client?.avatarImageUrl!}
              status={'Read'}
              createdAt={issue?.createdAt}
            />
          )}
        </GridItem>
        <GridItem overflowY={'scroll'}>
          {loading ? <LoadingIndicator /> : null}
        </GridItem>
        <GridItem p={2} bg={'glass.500'} boxShadow={'lg'}>
          <MessageInput onSend={onSend} />
        </GridItem>
      </Grid>
    </MainLayout>
  );
}
