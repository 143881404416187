import {
  Center,
  Grid,
  GridItem,
  Icon,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import {
  Issue,
  TalkMessage,
  UserMinimum,
  closeIssue,
  getIssueTalk,
  getTalkMessages,
  postTalkMessage,
  subscribePostTalkMessage,
} from 'api';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { MessageBalloon } from 'components/pages/talks/MessageBalloon';
import { MessageInput } from 'components/pages/talks/MessageInput';
import TalkScreenMenu from 'components/pages/talks/TalkScreenMenu';
import { useAuth } from 'hooks/use-auth';
import MainLayout from 'layouts/MainLayout';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MdRefresh } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { scrollToBottom } from 'utils/browser';

type Params = {
  issueId: string;
  talkId: string;
};

export default function TalkPage() {
  const navigate = useNavigate();
  const { user, isMemberOf } = useAuth();
  const { issueId, talkId } = useParams<Params>() as Params;

  const [loading, setLoading] = useState<boolean>(true);
  const [talkName, setTalkName] = useState<string>('');
  const [messages, setMessages] = useState<TalkMessage[]>([]);
  const [issue, setIssue] = useState<Issue>();
  const [client, setClient] = useState<UserMinimum>();
  const [isClosed, setIsClosed] = useState<boolean>(true);

  const [nextToken, setNextToken] = useState<string>();

  const isClient = user?.userId === client?.userId;

  // メッセージ Subscription
  useEffect(() => {
    let unmounted = false;
    console.debug('subscribing...');
    const subscription = subscribePostTalkMessage(
      talkId,
      (message) => {
        if (unmounted) {
          return;
        }
        setMessages((messages) => [
          message,
          ...messages.filter((x) => x.messageId !== message.messageId),
        ]);
      },
      (error) => console.error(error)
    );
    console.debug('subscribed');
    return () => {
      unmounted = true;
      console.debug('unsubscribing...');
      subscription?.unsubscribe();
      console.debug('unsubscribed');
    };
  }, [user, talkId]);

  useEffect(() => {
    let unmounted = false;
    (async () => {
      try {
        const { issue, talk } = await getIssueTalk(issueId, talkId);
        console.debug('getIssueTalk', { issue, talk });
        if (unmounted) {
          return;
        }
        setIssue(issue);
        if (issue.client) {
          setClient(issue.client);
          // トークルーム名
          if (isMemberOf('counselor')) {
            setTalkName(issue.client.name);
          } else {
            setTalkName(talk.counselor.name);
          }
        }
        setIsClosed(talk?.status === 'CLOSED');
        // メッセージ
        if (talk?.messages) {
          const { items: messages, nextToken } = talk.messages;
          setNextToken(nextToken ?? undefined);
          setMessages(messages ?? []);
        }
        setLoading(false);
        setTimeout(() => !unmounted && scrollToBottom(), 500);
      } catch (error) {
        console.error('getIssueTalk', error);
      }
    })();
    return () => {
      console.debug('unmounting...');
      unmounted = true;
      console.debug('unmounted');
    };
  }, [issueId, talkId, isMemberOf]);

  const loadPrevMessages = useCallback(
    async (nextToken: string) => {
      try {
        setLoading(true);
        const { items, nextToken: newNextToken } = await getTalkMessages(
          talkId,
          nextToken
        );
        console.debug(
          'getTalkMessages',
          items,
          newNextToken?.substring(0, 128)
        );
        setNextToken(newNextToken);
        setMessages((messages) => [...messages, ...items]);
        setLoading(false);
      } catch (error) {
        console.error('getTalkMessages', error);
      }
    },
    [talkId]
  );

  const createMessageBalloon = (item: TalkMessage) => {
    return (
      <MessageBalloon
        key={`message-balloon-${item.messageId}`}
        messageId={item.messageId}
        message={item.content ?? ''}
        mine={item.userId === user?.userId}
        userName={item.user?.name}
        avatarUrl={item.user?.avatarImageUrl!}
        status={'Read'}
        createdAt={item.createdAt}
      />
    );
  };

  const onSend = useCallback(
    async (content: string) => {
      try {
        const message = await postTalkMessage(talkId, content);
        console.debug('postTalkMessage', message);
        setMessages((messages) => [message, ...messages]);
        setTimeout(() => scrollToBottom(), 500);
      } catch (error) {
        console.error('postTalkMessage', error);
      }
    },
    [talkId]
  );

  // 戻るボタンを押したときの操作
  const handleBack = () => {
    navigate('/issues');
  };

  const onCloseIssueHandler = useCallback(
    async (resolved: boolean) => {
      const message = resolved
        ? 'この相談を解決済みにしますか？'
        : 'この相談を未解決で終了しますか？';
      if (!window.confirm(message)) {
        return;
      }
      try {
        setLoading(true);
        const result = await closeIssue(issueId, resolved ? talkId : undefined);
        setIsClosed(true);
        setIssue(result);
      } catch (error) {
        console.error('closeIssue', error);
        alert('相談の終了に失敗しました');
      } finally {
        setLoading(false);
      }
    },
    [issueId, talkId]
  );

  const headerCommands = useMemo(
    () =>
      isClient &&
      !isClosed && <TalkScreenMenu onCloseIssue={onCloseIssueHandler} />,
    [isClient, isClosed, onCloseIssueHandler]
  );

  return (
    <MainLayout
      title={talkName}
      handleBack={handleBack}
      headerCommands={headerCommands}
      hideFooter
    >
      <Grid templateRows={'auto 1fr auto'} height={'full'}>
        <GridItem
          bg={'glass.300'}
          boxShadow={'md'}
          overflowY={'auto'}
          maxHeight={'30vh'}
        >
          <VStack alignItems="end">
            <MessageBalloon
              messageId={''}
              message={issue?.content ?? ''}
              mine={issue?.clientId === user?.userId}
              userName={client?.name ?? ''}
              avatarUrl={client?.avatarImageUrl!}
              status={'Read'}
              createdAt={issue?.createdAt}
            />
          </VStack>
        </GridItem>
        <GridItem overflowY={'scroll'}>
          {!loading && nextToken ? (
            <VStack>
              <IconButton
                aria-label="もっと読み込む"
                icon={<Icon as={MdRefresh} fontSize={'24px'} />}
                variant={'ghost'}
                size={'lg'}
                onClick={() => loadPrevMessages(nextToken)}
                alignSelf={'center'}
              />
            </VStack>
          ) : null}
          {loading ? <LoadingIndicator /> : null}
          <VStack className="messagesArea" scrollSnapType={'y proximity'}>
            {messages
              .map((x) => x)
              .reverse()
              .map(createMessageBalloon)}
          </VStack>
        </GridItem>
        <GridItem p={2} bg={'glass.500'} boxShadow={'lg'}>
          {!loading && isClosed ? (
            <Center>このトークはクローズされています</Center>
          ) : (
            <MessageInput onSend={onSend} />
          )}
        </GridItem>
      </Grid>
    </MainLayout>
  );
}
