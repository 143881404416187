import { updateOwnProfile, UpdateOwnProfileInput } from 'api';
import { WelcomeCarousel } from 'components/pages/welcome/WelcomeCarousel';
import { useAuth } from 'hooks/use-auth';
import useModals from 'hooks/use-modals';
import MainLayout from 'layouts/MainLayout';
import { useState } from 'react';

const Welcome = () => {
  const [loading, setLoading] = useState(false);
  const { alert } = useModals();
  const { user, setUserAttributes } = useAuth();

  const onStart = async (userName: string) => {
    try {
      setLoading(true);
      const result = await updateOwnProfile({
        name: userName,
        welcomed: true,
      } as UpdateOwnProfileInput);
      console.debug('user profile updated', result);
      setUserAttributes({
        name: userName,
        welcomed: true,
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
      await alert(
        'ユーザー情報の更新に失敗しました\n何度か同じメッセージが表示される場合は運営元にお問い合わせください'
      );
    }
  };

  const siteTitle = process.env.REACT_APP_SITE_TITLE || 'お悩み相談室';

  return (
    <MainLayout title={`ようこそ、${siteTitle} へ`} hideFooter={true}>
      <WelcomeCarousel
        defaultUsername={user?.name}
        onStart={onStart}
        loading={loading}
      />
    </MainLayout>
  );
};

export default Welcome;
