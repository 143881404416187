import { Avatar } from '@chakra-ui/react';

type Props = {
  imageUrl?: string;
  name?: string;
  size?: number;
};

export function UserAvatar(props: Props) {
  return (
    <Avatar
      src={props.imageUrl || undefined}
      boxSize={props.size || undefined}
      name={props.name || undefined}
      size={'sm'}
      bg={props.imageUrl ? 'white' : undefined}
    />
  );
}
