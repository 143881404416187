import { Box, Button, HStack, Tooltip } from '@chakra-ui/react';
import { Issue, listOwnIssues } from 'api';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { IssueSummary } from 'components/pages/issues/IssueSummary';
import MainLayout from 'layouts/MainLayout';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ClientIssuesIndex() {
  const [issues, setIssues] = useState<Issue[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let unmounted = false;
    setLoading(true);
    (async () => {
      try {
        const { items, nextToken } = await listOwnIssues();
        console.debug('listOwnIssues', items, nextToken);
        if (!unmounted) {
          setIssues(items);
          setLoading(false);
        }
      } catch (error) {
        console.error('listOwnIssues', error);
      }
    })();
    return () => {
      unmounted = true;
    };
  }, []);

  const onIssueChanged = useCallback(
    (issue: Issue) => {
      setIssues((prev) => {
        const array = [...prev];
        const index = array.findIndex((x) => x.issueId === issue.issueId);
        if (index >= 0) {
          array.splice(index, 1, issue);
        }
        return array;
      });
    },
    [setIssues]
  );

  const navigate = useNavigate();

  const createIssueSummary = (issue: Issue) => {
    return (
      <IssueSummary
        key={issue.issueId}
        issue={issue}
        onIssueChanged={onIssueChanged}
      />
    );
  };

  const HeaderCommands: React.FC = () => {
    return (
      <HStack>
        <Tooltip
          hasArrow
          label="まだ相談がありません。あなたの悩みをお気軽にご相談ください！"
          isOpen={!loading && issues.length === 0}
          bg={'glass.500'}
          color={'black.500'}
          fontSize={'md'}
          p={4}
          m={2}
          rounded={'md'}
        >
          <Button
            size={'md'}
            rounded={'full'}
            aria-label="新しい相談を送る"
            onClick={() => navigate('/issues/create')}
          >
            新規相談
          </Button>
        </Tooltip>
      </HStack>
    );
  };

  return (
    <MainLayout title="相談" headerCommands={<HeaderCommands />}>
      <Box minH={'full'} p={4}>
        {loading ? <LoadingIndicator /> : issues?.map(createIssueSummary)}
      </Box>
    </MainLayout>
  );
}
