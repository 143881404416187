import {
  Box,
  Button,
  Flex,
  Grid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { BiHomeAlt, BiMessageSquareDots, BiUser } from 'react-icons/bi';
import { useLocation, useNavigate } from 'react-router-dom';

export function FooterNav() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const color = useColorModeValue('brand.900', 'brand.100');

  const NavButton: React.FC<{
    label: string;
    icon: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    isActive: boolean;
    onClick: () => void;
  }> = ({ label, icon, isActive, onClick }) => {
    return (
      <Button
        display={'inline-box'}
        variant={'unstyled'}
        onClick={onClick}
        bg={'glass.600'}
        color={color}
        rounded={0}
        m={0}
        p={2}
        height={'auto'}
      >
        <Flex
          fontSize={'xs'}
          direction={'column'}
          alignItems="center"
          opacity={isActive ? 1 : 0.7}
        >
          <Box>{icon}</Box>
          <Text>{label}</Text>
        </Flex>
      </Button>
    );
  };

  return (
    <Grid as="footer" autoColumns={'1fr'} autoFlow={'column'} boxShadow={'lg'}>
      <NavButton
        label="ホーム"
        icon={<BiHomeAlt size={24} />}
        isActive={pathname === '/'}
        onClick={() => navigate('/')}
      />
      <NavButton
        label="相談"
        icon={<BiMessageSquareDots size={24} />}
        isActive={pathname.startsWith('/issues')}
        onClick={() => navigate('/issues')}
      />
      <NavButton
        label="マイページ"
        icon={<BiUser size={24} />}
        isActive={pathname.startsWith('/mypage')}
        onClick={() => navigate('/mypage')}
      />
    </Grid>
  );
}
