/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const registerOrganization = /* GraphQL */ `
  mutation RegisterOrganization($input: RegisterOrganizationInput!) {
    registerOrganization(input: $input) {
      organizationId
      category
      name
      title
      createdAt
      updatedAt
      deleted
      lineAccountId
      avatarImageUrl
    }
  }
`;
export const registerClient = /* GraphQL */ `
  mutation RegisterClient($input: RegisterClientInput!) {
    registerClient(input: $input) {
      userId
      category
      name
      title
      organizationId
      affiliation
      profile
      introduction
      createdAt
      updatedAt
      deleted
      lineAccountId
      avatarImageUrl
      welcomed
    }
  }
`;
export const registerCounselor = /* GraphQL */ `
  mutation RegisterCounselor($input: RegisterCounselorInput!) {
    registerCounselor(input: $input) {
      userId
      category
      name
      title
      organizationId
      affiliation
      profile
      introduction
      createdAt
      updatedAt
      deleted
      lineAccountId
      avatarImageUrl
      welcomed
    }
  }
`;
export const updateOwnProfile = /* GraphQL */ `
  mutation UpdateOwnProfile($input: UpdateOwnProfileInput!) {
    updateOwnProfile(input: $input) {
      userId
      category
      name
      title
      organizationId
      affiliation
      profile
      introduction
      createdAt
      updatedAt
      deleted
      lineAccountId
      avatarImageUrl
      welcomed
    }
  }
`;
export const createIssue = /* GraphQL */ `
  mutation CreateIssue($input: CreateIssueInput!) {
    createIssue(input: $input) {
      issueId
      clientId
      category
      subcategory
      createdAt
      updatedAt
      deleted
      content
      status
      result
      resultDescription
      requestCount
      suggestionCount
      talkCount
      talks {
        talkId
        issueId
        counselorId
        createdAt
        updatedAt
        deleted
        status
      }
      client {
        userId
        category
        name
        title
        organizationId
        affiliation
        profile
        introduction
        createdAt
        updatedAt
        deleted
        lineAccountId
        avatarImageUrl
        welcomed
      }
    }
  }
`;
export const updateIssue = /* GraphQL */ `
  mutation UpdateIssue($issueId: ID!, $input: UpdateIssueInput!) {
    updateIssue(issueId: $issueId, input: $input) {
      issueId
      clientId
      category
      subcategory
      createdAt
      updatedAt
      deleted
      content
      status
      result
      resultDescription
      requestCount
      suggestionCount
      talkCount
      talks {
        talkId
        issueId
        counselorId
        createdAt
        updatedAt
        deleted
        status
      }
      client {
        userId
        category
        name
        title
        organizationId
        affiliation
        profile
        introduction
        createdAt
        updatedAt
        deleted
        lineAccountId
        avatarImageUrl
        welcomed
      }
    }
  }
`;
export const closeIssue = /* GraphQL */ `
  mutation CloseIssue($issueId: ID!) {
    closeIssue(issueId: $issueId) {
      issueId
      clientId
      category
      subcategory
      createdAt
      updatedAt
      deleted
      content
      status
      result
      resultDescription
      requestCount
      suggestionCount
      talkCount
      talks {
        talkId
        issueId
        counselorId
        createdAt
        updatedAt
        deleted
        status
      }
      client {
        userId
        category
        name
        title
        organizationId
        affiliation
        profile
        introduction
        createdAt
        updatedAt
        deleted
        lineAccountId
        avatarImageUrl
        welcomed
      }
    }
  }
`;
export const openTalk = /* GraphQL */ `
  mutation OpenTalk($input: OpenTalkInput) {
    openTalk(input: $input) {
      talkId
      issueId
      counselorId
      createdAt
      updatedAt
      deleted
      status
      messages {
        nextToken
      }
      counselor {
        userId
        category
        name
        title
        organizationId
        affiliation
        profile
        introduction
        createdAt
        updatedAt
        deleted
        lineAccountId
        avatarImageUrl
        welcomed
      }
    }
  }
`;
export const postTalkMessage = /* GraphQL */ `
  mutation PostTalkMessage($input: PostTalkMessageInput!) {
    postTalkMessage(input: $input) {
      talkId
      messageId
      userId
      createdAt
      updatedAt
      deleted
      content
      user {
        userId
        category
        name
        title
        organizationId
        affiliation
        profile
        introduction
        createdAt
        updatedAt
        deleted
        lineAccountId
        avatarImageUrl
        welcomed
      }
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage($input: DeleteTalkMessageInput!) {
    deleteMessage(input: $input) {
      talkId
      messageId
      userId
      createdAt
      updatedAt
      deleted
      content
      user {
        userId
        category
        name
        title
        organizationId
        affiliation
        profile
        introduction
        createdAt
        updatedAt
        deleted
        lineAccountId
        avatarImageUrl
        welcomed
      }
    }
  }
`;
