import { Center, GridItem } from '@chakra-ui/react';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { PrivatePage, useAuth } from 'hooks/use-auth';
import { ModalProvider } from 'hooks/use-modals';
import { useSiteConfig } from 'hooks/use-site-config';
import MainLayout from 'layouts/MainLayout';
import Talk from 'pages/issues/:issueId/talks/:talkId';
import NewTalk from 'pages/issues/:issueId/talks/new';
import ClientIssuesIndex from 'pages/issues/clientIndex';
import CounselorIssuesIndex from 'pages/issues/counselorIndex';
import IssueCreate from 'pages/issues/create';
import MyPage from 'pages/MyPage';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Welcome from './pages/Welcome';

function App() {
  const { isLoading, isAuthenticated, user, isMemberOf } = useAuth();
  const { isLoading: isLoading2 } = useSiteConfig();

  const FilteredHome = () => {
    if (!isAuthenticated) {
      return <Navigate to="/login" />;
    }
    // welcomed フラグが立っていればホーム画面
    if (user?.welcomed) {
      return (
        <MainLayout title="ホーム">
          <Home />
        </MainLayout>
      );
    } else {
      return <Welcome />;
    }
  };

  const FilteredRoute: React.FC = ({ children }) => {
    if (user?.welcomed) {
      return (
        <>
          {children}
          <Outlet />
        </>
      );
    }
    return <Navigate to="/" />;
  };

  if (isLoading || isLoading2) {
    return (
      <GridItem area="main" h={'full'}>
        <Center h={'full'}>
          <LoadingIndicator size="xl" thickness="4px" speed="1s" />
        </Center>
      </GridItem>
    );
  }

  const IssuesIndex = () => {
    return isMemberOf('counselor') ? (
      <CounselorIssuesIndex />
    ) : (
      <ClientIssuesIndex />
    );
  };

  const IssueCreatePage = () => {
    return isMemberOf('counselor') ? <Navigate to="/" /> : <IssueCreate />;
  };

  return (
    <ModalProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path="login" element={<Login user={user} />} />
            <Route index element={<FilteredHome />} />
            <Route element={<PrivatePage />}>
              <Route path="mypage">
                <Route index element={<MyPage />} />
              </Route>
              <Route path="issues" element={<FilteredRoute />}>
                <Route index element={<IssuesIndex />} />
                <Route path="create" element={<IssueCreatePage />} />
                <Route path=":issueId">
                  <Route path="talks">
                    <Route path="new" element={<NewTalk />} />
                    <Route path=":talkId" element={<Talk />} />
                  </Route>
                </Route>
              </Route>
            </Route>
            {/* Not Found */}
            <Route element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ModalProvider>
  );
}

export default App;
