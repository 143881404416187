import { Badge } from '@chakra-ui/react';

const statusProps = [
  {
    key: 'CLOSED',
    label: 'クローズ',
    color: 'gray',
  },
];

type Props = {
  status?: String | null;
};

const StatusBadge = ({ status }: Props) => {
  const attrs = statusProps.find((x) => x.key === status);
  if (!attrs) return null;
  const { label, color } = attrs;
  return (
    <Badge variant="solid" colorScheme={color}>
      {label}
    </Badge>
  );
};

export default StatusBadge;
