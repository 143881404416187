export const awsConfig = {
  "aws_project_region": process.env.REACT_APP_AWS_REGION,
  "aws_appsync_graphqlEndpoint": process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
  "aws_appsync_region": process.env.REACT_APP_AWS_REGION,
  "aws_appsync_authenticationType": process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE,
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT,
      responseType: 'code',
    },
  },
};
