import { Box, BoxProps, useStyleConfig } from '@chakra-ui/react';

export function Card(props: BoxProps) {
  const { children, ...rest } = props;

  const styles = useStyleConfig('Card');

  return (
    <Box __css={styles} {...rest}>
      {children}
    </Box>
  );
}
