import { Badge, Box, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { TalkMessage, User } from 'API';
import { UserAvatar } from 'components/common/UserAvatar';
import { useAuth } from 'hooks/use-auth';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import ResolvedBadge from '../ResolvedBadge';

type Props = {
  issueId: string;
  talkId: string;
  user?: User;
  lastMessage?: TalkMessage;
  status: string;
  isSolution: boolean;
};

export function TalkSummary(props: Props) {
  const navigate = useNavigate();
  const { user } = useAuth();

  function timestamp(): string {
    if (!props.lastMessage?.createdAt) return '';
    const date = DateTime.fromISO(props.lastMessage?.createdAt).toLocal();
    const format = date.hasSame(DateTime.local(), 'day')
      ? 'HH:mm'
      : 'MM/dd HH:mm';
    return date.toFormat(format);
  }

  const LastMessage = () => {
    if (!props.lastMessage) return null;
    const badge =
      props.lastMessage.userId !== user?.userId ? (
        <Badge colorScheme={'red'} mr={'auto'}>
          未返信
        </Badge>
      ) : null;
    return (
      <VStack spacing={2} w={'full'}>
        <Text w={'full'} whiteSpace={'pre-line'} noOfLines={4} fontSize={'sm'}>
          {props.lastMessage?.content}
        </Text>
        <HStack spacing={2} w={'full'}>
          {badge}
          <Box fontSize={'xs'} color={'gray.500'} ml={'auto'}>
            {timestamp()}
          </Box>
        </HStack>
      </VStack>
    );
  };

  const userName = props.user?.name ?? user?.name ?? '名無し';

  return (
    <HStack
      bg={'glass.500'}
      p={2}
      spacing={2}
      borderRadius="md"
      w={'full'}
      alignItems={'flex-start'}
      key={`message-${props.talkId}`}
      onClick={() => navigate(`/issues/${props.issueId}/talks/${props.talkId}`)}
    >
      <VStack>
        {props.user && (
          <UserAvatar
            imageUrl={props.user.avatarImageUrl || undefined}
            name={props.user.name}
          />
        )}
        {props.isSolution && <ResolvedBadge />}
      </VStack>
      <VStack spacing={1} w={'full'} alignItems={'flex-start'}>
        {user && (
          <Heading as={'span'} fontSize={'sm'}>
            {userName}
          </Heading>
        )}
        <LastMessage />
      </VStack>
    </HStack>
  );
}
