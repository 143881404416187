import { Flex, IconButton, Textarea } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { MdSend } from 'react-icons/md';

type Props = {
  onSend?: (content: string) => Promise<void>;
};

export function MessageInput(props: Props) {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [sending, setSending] = useState(false);

  const fitHeightToContent = (e: HTMLTextAreaElement) => {
    e.style.height = 'inherit';
    e.style.height = `${e.scrollHeight + 2}px`;
    // In case you have a limitation
    const limit = 200;
    e.style.height = `${Math.min(e.scrollHeight, limit)}px`;
  };

  const onSendClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (props.onSend && inputRef.current?.value) {
      setSending(true);
      inputRef.current.disabled = true;
      try {
        await props.onSend(inputRef.current?.value);
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (inputRef.current) {
          inputRef.current.disabled = false;
          fitHeightToContent(inputRef.current);
          setSending(false);
        }
      }
    }
  };

  return (
    <Flex direction={'row'}>
      <Textarea
        ref={inputRef}
        onInput={(e) => fitHeightToContent(e.target as HTMLTextAreaElement)}
        rows={1}
        resize={'none'}
        placeholder="ここに返信を入力してください"
      ></Textarea>
      <IconButton
        width={'48px'}
        ml={1}
        aria-label="送信"
        icon={<MdSend size={24} />}
        isLoading={sending}
        onClick={onSendClick}
      />
    </Flex>
  );
}
