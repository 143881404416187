import { Box, FormControl, FormLabel } from '@chakra-ui/react';
import { Issue, listIssuesByCategory } from 'api';
import { Card } from 'components/common/Card';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { NoItemIndicator } from 'components/common/NoItemIndicator';
import { CategorySelect } from 'components/pages/issues/CategorySelect';
import { IssueSummary } from 'components/pages/issues/IssueSummary';
import { SubcategorySelect } from 'components/pages/issues/SubcategorySelect';
import { useSiteConfig } from 'hooks/use-site-config';
import MainLayout from 'layouts/MainLayout';
import { Fragment, useEffect, useState } from 'react';

export default function CounselorIssuesIndex() {
  const [issues, setIssues] = useState<Issue[]>([]);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');

  useEffect(() => {
    let unmounted = false;
    setLoading(true);
    (async () => {
      try {
        const { items, nextToken } = await listIssuesByCategory({
          issueId: null,
          category,
          subcategory,
        });
        console.debug('listIssuesByCategory', items, nextToken);
        if (!unmounted) {
          setIssues(items);
          setLoading(false);
        }
      } catch (error) {
        console.error('listIssuesByCategory', error);
      }
    })();
    return () => {
      unmounted = true;
    };
  }, [category, subcategory]);

  const { useSubcategory } = useSiteConfig();

  const createIssueSummary = (issue: Issue) => {
    return (
      <Fragment key={`issue-summary-${issue.issueId}`}>
        <IssueSummary issue={issue} />
      </Fragment>
    );
  };

  return (
    <MainLayout title="相談を検索">
      <Card>
        <FormControl>
          <FormLabel htmlFor="category-select">カテゴリ</FormLabel>
          <CategorySelect
            id="category-select"
            name="category"
            onChange={(category) => setCategory(category)}
          />
        </FormControl>
        {useSubcategory ? (
          <FormControl mt={2}>
            <FormLabel htmlFor="category-select">相談希望の職種</FormLabel>
            <SubcategorySelect
              id="subcategory-select"
              name="subcategory"
              onChange={(subcategory) => setSubcategory(subcategory)}
            />
          </FormControl>
        ) : null}
      </Card>
      <Box minH={'full'} p={4} mt={4}>
        {loading ? (
          <LoadingIndicator />
        ) : issues.length ? (
          issues.map(createIssueSummary)
        ) : (
          <NoItemIndicator message={'まだ相談がありません。'} />
        )}
      </Box>
    </MainLayout>
  );
}
