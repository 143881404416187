import { useCallback, useEffect, useRef, useState } from 'react';

export const useUnmountRef = (): React.RefObject<boolean> => {
  const unmountRef = useRef(false);
  useEffect(
    () => () => {
      unmountRef.current = true;
    },
    []
  );
  return unmountRef;
};

export function useSafeState<T>(
  unmountRef: React.RefObject<boolean>,
  defaultValue: T
): [T, (value: T) => void] {
  const [state, changeState] = useState(defaultValue);
  const wrapChangeState = useCallback(
    (v: any) => {
      if (!unmountRef.current) {
        changeState(v);
      }
    },
    [changeState, unmountRef]
  );

  return [state, wrapChangeState];
}
