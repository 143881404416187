import { Select } from '@chakra-ui/react';

type Props = {
  id?: string;
  name?: string;
  onChange: (subcategory: string) => any;
};

export function SubcategorySelect(props: Props) {
  const categories = [
    { value: '', label: '特になし' },
    { value: 'PSYCHOLOGIST', label: '公認心理士・臨床心理士' },
    { value: 'DOCTOR', label: '医師' },
    { value: 'ATTORNEY', label: '弁護士' },
  ];

  const createSubcategoryOption = (subcategory: {
    value: string;
    label: string;
  }) => {
    return (
      <option
        key={`subcategory-option-${subcategory.value}`}
        value={subcategory.value}
      >
        {subcategory.label}
      </option>
    );
  };

  const handleSubcategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { target } = e;
    const { value } = target;
    props.onChange(value);
  };

  return (
    <Select
      id={props.id || 'subcategory-select'}
      name={props.name || 'subcategory'}
      onChange={handleSubcategoryChange}
    >
      {categories.map(createSubcategoryOption)}
    </Select>
  );
}
