import { API, GraphQLResult } from '@aws-amplify/api';
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { Mutation, MutationCreateIssueArgs } from 'API';
import { Card } from 'components/common/Card';
import { CategorySelect } from 'components/pages/issues/CategorySelect';
import { SubcategorySelect } from 'components/pages/issues/SubcategorySelect';
import { createIssue } from 'graphql/mutations';
import useModals from 'hooks/use-modals';
import MainLayout from 'layouts/MainLayout';
import { useState } from 'react';
import { MdSend } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

export default function IssueCreate() {
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [content, setContent] = useState('');

  const [sending, setSending] = useState(false);

  const execCreateIssue = async () => {
    const result = (await API.graphql({
      query: createIssue,
      variables: {
        input: {
          category,
          subcategory,
          content,
        },
      } as MutationCreateIssueArgs,
    })) as GraphQLResult<Mutation>;
    return result;
  };

  const navigate = useNavigate();
  const { alert, confirm } = useModals();

  const handleBack = async () => {
    if (
      !(await confirm('一覧ページに戻りますか？\n入力内容はリセットされます。'))
    ) {
      return;
    }
    navigate('/issues');
  };

  const handleSubmit = async () => {
    if (!(await confirm('この内容で送信します。\nよろしいですか？'))) {
      return;
    }
    try {
      setSending(true);
      const result = await execCreateIssue();
      console.debug('issue created', result);
      navigate('/issues');
    } catch (error: any) {
      console.error(error);
      setSending(false);
      alert(
        '送信に失敗しました。\n何度か同じメッセージが表示される場合は運営元にお問い合わせください'
      );
    }
  };

  const buttonIsDisabled = () => sending || !category || !content;

  return (
    <MainLayout
      title="新しい相談を書く"
      handleBack={handleBack}
      hideFooter={true}
    >
      <Card>
        <VStack gap={2}>
          <FormControl>
            <FormLabel htmlFor="category-select">カテゴリ</FormLabel>
            <CategorySelect
              id="category-select"
              name="category"
              onChange={(category) => setCategory(category)}
            />
            <FormHelperText>
              あなたの相談内容にもっとも近いカテゴリを選んでください
            </FormHelperText>
          </FormControl>
          {false && (
            <FormControl>
              <FormLabel htmlFor="category-select">相談希望の職種</FormLabel>
              <SubcategorySelect
                id="subcategory-select"
                name="subcategory"
                onChange={(subcategory) => setSubcategory(subcategory)}
              />
            </FormControl>
          )}
          <FormControl>
            <FormLabel htmlFor="category-select">相談内容</FormLabel>
            <Textarea
              placeholder="できるだけ詳細にご記入ください"
              disabled={sending}
              isRequired={true}
              h={200}
              resize={'vertical'}
              onChange={(e) => setContent(e.target.value)}
            />
          </FormControl>
          <Button
            leftIcon={<MdSend size={24} />}
            colorScheme="purple"
            isLoading={sending}
            isDisabled={buttonIsDisabled()}
            onClick={handleSubmit}
            alignSelf={'flex-start'}
          >
            相談を送信する
          </Button>
        </VStack>
      </Card>
    </MainLayout>
  );
}
