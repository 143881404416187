import { Box, Text } from '@chakra-ui/react';

type Props = {
  message: string;
  color?: string;
};

export function NoItemIndicator(props: Props) {
  return (
    <Box color={props.color}>
      <Text fontSize={'sm'} whiteSpace={'pre-line'}>
        {props.message}
      </Text>
    </Box>
  );
}
