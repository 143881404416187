import { me, registerClient } from 'api';
import Amplify, { Auth } from 'aws-amplify';
import { awsConfig } from 'awsConfig';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Navigate, Outlet } from 'react-router-dom';

Amplify.configure(awsConfig);

type Maybe<T> = T | null;

// Cognito から得られるユーザー情報
interface AuthUserData {
  name: string;
  username: string;
  groups: string[];
  email?: string;
}

// DB から得られるユーザー情報 (Amplify の User オブジェクトと同じインターフェイス)
interface DomainUserInfo {
  affiliation: Maybe<string>;
  avatarImageUrl: Maybe<string>;
  category: string;
  createdAt: string;
  deleted: Maybe<boolean>;
  introduction: Maybe<string>;
  lineAccountId: Maybe<string>;
  organizationId: Maybe<string>;
  profile: Maybe<string>;
  title: Maybe<string>;
  updatedAt: Maybe<string>;
  userId: string;
  welcomed: Maybe<boolean>;
}

// Cognito から得られるユーザー情報と DB から得られるユーザー情報をマージしたもの
export type UserInfo = AuthUserData & DomainUserInfo;

interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: UserInfo | null;
  // signUp: (username: string, password: string) => Promise<Result>;
  // confirmSignUp: (verificationCode: string) => Promise<Result>;
  signIn: (username: string, password: string) => Promise<Result>;
  signInWith: (provider: string) => void;
  signOut: () => Promise<Result>;
  isMemberOf: (group: string) => boolean;
  setUserAttributes: (userInfo: Partial<UserInfo>) => void;
}

interface Result {
  success: boolean;
  message: string;
}

const authContext = createContext({} as UseAuth);

export const ProvideAuth: React.FC = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}> {children} </authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<UserInfo | null>(null);
  // const [password, setPassword] = useState('');

  // Auth.currentAuthenticatedUser() の戻り値からプロパティを分解
  function extractUserData(userData: any): AuthUserData {
    const { username, name } = userData;
    const token = userData.signInUserSession?.idToken?.payload;
    const email = token?.email;
    const groups = token?.['cognito:groups'] || [];
    return {
      username,
      name,
      email,
      groups,
    };
  }

  // DB からユーザー情報を取得 (なければ登録)
  const ensureDomainUserInfo = async (props: {
    name: string;
    lineAccountId?: string;
    picture?: string;
  }): Promise<DomainUserInfo> => {
    try {
      const info = await me();
      if (info) {
        console.debug('me', info);
        return info;
      }
    } catch (error) {
      console.error('[me] failed to fetch user info', error);
      throw error;
    }
    try {
      const registeredUser = await registerClient(props);
      console.debug('registered', registeredUser);
      return registeredUser;
    } catch (error) {
      console.error('[registerClient] failed to register client', error);
      throw error;
    }
  };

  const createUserInfo = useCallback(
    async (authUser: any): Promise<UserInfo> => {
      const userData = extractUserData(authUser);
      const lineAccountId = userData.username.startsWith('LINE_')
        ? userData.username.replace('LINE_', '')
        : undefined;
      const idToken = authUser.signInUserSession?.idToken?.payload;
      const picture = idToken?.picture;
      const userInfo = await ensureDomainUserInfo({
        name: authUser.name || '_',
        lineAccountId,
        picture,
      });
      return { ...userData, ...userInfo };
    },
    []
  );

  useEffect(() => {
    async function getUser() {
      try {
        const authUser = await Auth.currentAuthenticatedUser();
        console.debug('authUser', authUser);
        const userInfo = await createUserInfo(authUser);
        setUser(userInfo);
        setIsAuthenticated(true);
      } catch {
        setUser(null);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    }
    getUser();
  }, [createUserInfo]);

  // const signUp = async (username: string, password: string) => {
  //   try {
  //     await Auth.signUp({ username, password });
  //     setUsername(username);
  //     setPassword(password);
  //     return { success: true, message: '' };
  //   } catch (error) {
  //     return {
  //       success: false,
  //       message: '認証に失敗しました。',
  //     };
  //   }
  // };

  // const confirmSignUp = async (verificationCode: string) => {
  //   try {
  //     await Auth.confirmSignUp(username, verificationCode);
  //     const result = await signIn(username, password);
  //     setPassword('');
  //     return result;
  //   } catch (error) {
  //     return {
  //       success: false,
  //       message: '認証に失敗しました。',
  //     };
  //   }
  // };

  const signIn = async (username: string, password: string) => {
    try {
      const authUser = await Auth.signIn(username, password);
      console.debug('signIn', authUser);
      const userInfo = await createUserInfo(authUser);
      setUser(userInfo);
      setIsAuthenticated(true);
      return { success: true, message: '' };
    } catch (error) {
      setUser(null);
      setIsAuthenticated(false);
      return {
        success: false,
        message: '認証に失敗しました。',
      };
    }
  };

  const signInWith = async (provider: string) => {
    try {
      // @ts-ignore
      Auth.federatedSignIn({ provider });
    } catch (error) {
      setUser(null);
      setIsAuthenticated(false);
      return {
        success: false,
        message: '認証に失敗しました。',
      };
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setUser(null);
      setIsAuthenticated(false);
      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: 'ログアウトに失敗しました。',
      };
    }
  };

  const isMemberOf = (group: string) => {
    return !!user?.groups?.some((x) => x.toLowerCase() === group.toLowerCase());
  };

  const setUserAttributes = (userInfo: Partial<UserInfo>) => {
    if (!user) {
      return;
    }
    setUser({ ...user, ...userInfo });
  };

  return {
    isLoading,
    isAuthenticated,
    user,
    // signUp,
    // confirmSignUp,
    signIn,
    signInWith,
    signOut,
    isMemberOf,
    setUserAttributes,
  };
};

export const PrivatePage: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? (
    <>
      {children}
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
};
