import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  IconButton,
  Text,
  useBoolean,
  useEditableControls,
  VStack,
} from '@chakra-ui/react';
import { updateOwnProfile, UpdateOwnProfileInput } from 'api';
import { Card } from 'components/common/Card';
import { useAuth } from 'hooks/use-auth';
import useModals from 'hooks/use-modals';
import { useUnmountRef } from 'hooks/use-safe-state';
import MainLayout from 'layouts/MainLayout';
import { MdCheckCircle, MdClose, MdEdit } from 'react-icons/md';

function EditableControls() {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls();

  return (
    <Box p={1}>
      {isEditing ? (
        <ButtonGroup
          justifyContent="center"
          alignItems={'center'}
          size="xs"
          spacing={2}
        >
          <IconButton
            aria-label="変更"
            variant={'ghost'}
            icon={<MdCheckCircle size={'20px'} />}
            {...getSubmitButtonProps()}
          />
          <IconButton
            aria-label="キャンセル"
            variant={'ghost'}
            icon={<MdClose size={'20px'} />}
            {...getCancelButtonProps()}
          />
        </ButtonGroup>
      ) : (
        <Flex justifyContent="center">
          <IconButton
            aria-label="編集"
            variant={'ghost'}
            size="xs"
            icon={<MdEdit size={'20px'} />}
            {...getEditButtonProps()}
          />
        </Flex>
      )}
    </Box>
  );
}

const MyPage = () => {
  const { user, signOut, setUserAttributes } = useAuth();
  const { alert, confirm } = useModals();
  const [isSigningOut, setIsSigningOut] = useBoolean(false);
  const unmountref = useUnmountRef();

  if (!user) {
    return null;
  }

  const handleNameChange = (newName: string) => {
    (async () => {
      if (!(await confirm(`ユーザー名を ${newName} に変更しますか？`))) {
        return;
      }
      const result = await updateOwnProfile({
        name: newName,
      } as UpdateOwnProfileInput);
      console.debug('user profile updated', result);
      if (!unmountref.current) {
        setUserAttributes({
          name: newName,
        });
      }
    })();
  };

  const handleSignOut = () => {
    (async () => {
      if (await confirm('ログアウトしますか？')) {
        setIsSigningOut.on();
        try {
          await signOut();
          setIsSigningOut.off();
        } catch (error: any) {
          setIsSigningOut.off();
          await alert(error.message);
        }
      }
    })();
  };

  const EmailAddressCard = () => {
    if (!user.email) {
      return null;
    }
    return (
      <Card>
        <Text fontSize={'sm'}>メールアドレス</Text>
        {user.email ? <Text>{user.email}</Text> : <Text>未設定</Text>}
      </Card>
    );
  };

  return (
    <MainLayout title={`マイページ`}>
      <Card>
        <VStack spacing={4}>
          <Box>
            <Avatar
              src={user.avatarImageUrl || undefined}
              boxSize={'120px'}
              bg={user.avatarImageUrl ? 'white' : undefined}
            />
          </Box>
          <Box>
            <Editable
              defaultValue={user.name}
              textAlign="center"
              isPreviewFocusable={false}
              fontSize={'lg'}
              onSubmit={handleNameChange}
            >
              <Flex alignItems={'center'}>
                <Box>
                  <EditablePreview />
                  <EditableInput />
                </Box>
                <EditableControls />
              </Flex>
            </Editable>
          </Box>
        </VStack>
      </Card>
      <EmailAddressCard />
      <Card p={0}>
        <Button
          variant={'ghost'}
          width={'full'}
          color="brand.500"
          isLoading={isSigningOut}
          onClick={handleSignOut}
        >
          ログアウト
        </Button>
      </Card>
    </MainLayout>
  );
};

export default MyPage;
